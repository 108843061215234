<template>
	<div class="buttons-wrapper" :class="`${value.alignment}`">
		<base-button
			v-for="(button, i) in this.value['buttons-group']"
			:type="button.btn_type"
			:key="i"
			:to="!isPhoneNumber ? button.link : null"
			:url="isPhoneNumber ? button.link : null"
			:icon="button.icon"
            :title="button.link_title"
		>
			<cms-text :props="{ clear: true}" :value="`${prefix}.buttons-group.${i}.btn_content`"/>
		</base-button>
	</div>
</template>
<script>
export default {
	computed: {
		isPhoneNumber () {
			return this.value['buttons-group'].every(button => button.link.includes('tel'))
		}
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>

.buttons-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	margin-top: 3rem;
	@include media-breakpoint-up(md) {
		gap: 30px;
		flex-direction: row;
		&.left {
			justify-content: flex-start;
		}
		&.center {
			justify-content: center;
		}
		&.right {
			justify-content: flex-end;
		}
	}
	@include media-breakpoint-up(xl) {
		margin-top: 6rem;
	}
	@include media-breakpoint-up(xl) {
		& a:first-child {
			margin-right: 1.5rem;
		}
	}
	@include media-breakpoint-up(xxl) {
		& a:first-child {
			margin-right: 2rem;
		}
	}
}
</style>
